<template>
    <div>
        <!-- Page content -->
        <component :is="pageWrapper">
            <slot name="page" :item="item" v-for="(item) in paginatedData[currentPage]"></slot>
        </component>
        <component :is="navWrapper">
            <tr>
                <td :colspan="colspan">
                    <!-- Navigation menu -->
                    <slot name="navigation" v-if="(totalPages > 1)">
                        <div class="navigation">
                            <a class="page-button" @click="changePage(0)" v-if="(totalPages > 1)">&lt;&lt;</a>
                            <a class="page-button" @click="changePage(currentPage - 1)" v-if="(totalPages > 1)">&lt;</a>
                            <span v-for="(page, index) in getPagesToShow" :key="index">
                                <a class="page-button" :class="{selected: (page === currentPage)}" @click="changePage(page)">{{ page + 1 }}</a>
                            </span>
                            <a class="page-button" @click="changePage(currentPage + 1)" v-if="(totalPages > 1)">&gt;</a>
                            <a class="page-button" @click="changePage(paginatedData.length - 1)" v-if="(totalPages > 1)">&gt;&gt;</a>
                        </div>
                    </slot>
                </td>
            </tr>
        </component>
    </div>
</template>

<script>
/**
 * Paginator component for data tables
 * Takes a one dimension array, paginate it and display navigation controls
 * @author Alba IT
 * @version 0.1.0
 */
export default {
    name: 'Paginator',
    props: {
        /**
         * The data Array to paginate
         */
        data: {
            type: Array,
            required: true
        },
        /**
         * Entries to display per page
         */
        perPage: {
            type: Number,
            default: 25
        },
        /**
         * Navigation pages to display
         */
        showPages: {
            type: Number,
            default: 5
        },
        /**
         * Page wrapper HTML tag
         */
        pageWrapper: {
            type: String,
            default: 'div'
        },
        /**
         * Navigation wrapper HTML tag
         */
        navWrapper: {
            type: String,
            default: 'div'
        },
        /**
         * Navigation colspan until I find a nicer way to do this
         */
        colspan: {
            type: String,
            default: "1"
        }
    },
    data: function() {
        return {
            paginatedData: [],
            totalPages: 0,
            currentPage: 0
        };
    },
    computed: {
        /**
         * Get the number of pages to show in the paginator navigation
         * @returns {number}
         */
        getPagesToShow: function() {
            // If there are more pages than pages we want to show in the navigation
            if (this.totalPages > this.showPages) {
                // Get the shift value according to the number of pages to show in the navigation
                const shift = Math.floor(this.showPages / 2);

                // Calculate the start page
                let start = this.currentPage - shift;
                if (start < 0) {
                    // If the calculated start page is less than 0, make it 0
                    start = 0;
                }
                // Calculate the end page
                let end = this.currentPage + shift + 1;
                if (end > this.totalPages) {
                    // If the calculated end page is more than the total pages, make it the total pages
                    end = this.totalPages;
                }

                // Generate the pages entries
                const pages = [];
                for (let i = start; i < end; i ++) {
                    pages.push(i);
                }

                // Return the pages to show
                return pages;
            } else {
                // Else, make the pages to show the total pages
                const pages = [];

                for (let i = 0; i < this.totalPages; i++) {
                    pages.push(i);
                }

                return pages;
            }
        }
    },
    watch: {
        /**
         * Repaginate de data on update
         * This is needed because data comes from async HTTP API calls in the parent component
         * @returns {void}
         */
        data: function() {
            this.paginateData();
        }
    },
    methods: {
        /**
         * Paginate the original data array into a paginated array
         * @returns {void}
         */
        paginateData: function() {
            // Generate basic pagination informations
            this.totalPages = Math.ceil(this.data.length / this.perPage);
            //const pageBegin = this.currentPage * this.perPage;
            //const pageEnd = pageBegin + this.perPage;

            // Loop this.totalPages times
            for (let i = 0; i < this.totalPages; i++) {
                // Instantiate the sub-array
                this.paginatedData.push([]);

                // Loop this.perPage times
                for (let j = 0; j < this.perPage; j++) {
                    // Calculate the index in the origin array
                    const index = (i * this.perPage) + j;
                    
                    // If there is still data available at this point
                    if (typeof this.data[index] !== 'undefined') {
                        // Push the original data entry into the paginated array
                        this.paginatedData[i][j] = this.data[index];
                    }
                }
            }
        },
        /**
         * Change the page number and emit an event with the related data
         * @returns {void}
         */
        changePage: function(pageNumber) {
            if (pageNumber >= 0 && pageNumber < this.totalPages) {
                // Change the current page
                this.currentPage = pageNumber;
            }
        }
    },
    mounted: function() {
        // Init the paginated data array
        this.paginateData();
    }
}
</script>

<style lang="scss" scoped>
.navigation {
    text-align: center;
    margin-top: 12px;
}

.page-button {
    color: #000000;
    border: 1px solid #000000;
    margin: 0 1px;
    padding: 4px;
}
.selected {
    color: #FFFFFF;
    background-color: #000000;
}
</style>
