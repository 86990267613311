<template>
    <div>
        <paginator :perPage="perPage" :data="articles">
            <template v-slot:page="slotProps">
                <directus-article :getter="slotProps.item.id" :isLight="isLight" />
            </template>
            <template v-slot:navigation></template>
        </paginator>
    </div>
</template>

<script>
import DirectusArticle from '@/components/ext/DirectusArticle.vue';
import Paginator from '@/components/layout/Paginator.vue';

/**
 * Article component for displaying a Directus articles list
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'DirectusArticlesList',
    components: {
        DirectusArticle,
        Paginator
    },
    props: {
        perPage:  {
            type: Number,
            default: 5
        },
        /**
         * Display in light mode (only disply the article first this.capLength characters)
         */
        isLight: {
            type: Boolean,
            default: false
        },
        /**
         * The Directus articles objects list
         */
        articles: {
            type: Array,
            required: true
        }
    },
    data: function() {
        return {
            page: 1
        };
    }
}
</script>